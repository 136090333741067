.s-reportFilterSection {
	margin-bottom: 25px;
}

.s-report-user {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
}

.reportButton {
	background-color: #f1f5ff;
	color: #4a7dff;
	border-radius: 11px;
	border-color: #4a7dff;
	padding: 8px 16px 8px 16px;
}
