//
// Ribbon
//

// Base
.ribbon {
	position: relative;

	// Ribbon target
	.ribbon-label {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 5px 10px;
		position: absolute;
		z-index: 1;
		background-color: var(--kt-ribbon-label-bg);
		box-shadow: var(--kt-ribbon-label-box-shadow);
		color: var(--kt-primary-inverse);
		top: 50%;
		right: 0;
		transform: translateX(5px) translateY(-50%);

		> .ribbon-inner {
			z-index: -1;
			position: absolute;
			padding: 0;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}

		&:after {
			border-color: var(--kt-ribbon-label-border-color);
		}
	}

	// Vertical aligment
	&-vertical {
		.ribbon-label {
			padding: 5px 10px;
			min-width: 36px;
			min-height: 46px;
			text-align: center;
		}
	}

	&.ribbon-top {
		.ribbon-label {
			top: 0;
			transform: translateX(-15px) translateY(-4px);
			border-bottom-right-radius: $border-radius;
			border-bottom-left-radius: $border-radius;
		}
	}

	&.ribbon-bottom {
		.ribbon-label {
			border-top-right-radius: $border-radius;
			border-top-left-radius: $border-radius;
		}
	}

	&.ribbon-start {
		.ribbon-label {
			top: 50%;
			left: 0;
			right: auto;
			transform: translateX(-5px) translateY(-50%);
			border-top-right-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
		}
	}

	&.ribbon-end {
		.ribbon-label {
			border-top-left-radius: $border-radius;
			border-bottom-left-radius: $border-radius;
		}
	}
}

// Clip style
.ribbon.ribbon-clip {
	&.ribbon-start {
		.ribbon-label {
			left: -5px;

			.ribbon-inner {
				border-top-right-radius: $border-radius;
				border-bottom-right-radius: $border-radius;

				&:before,
				&:after {
					content: '';
					position: absolute;
					border-style: solid;
					border-color: transparent !important;
					bottom: -10px;
				}

				&:before {
					border-width: 0 10px 10px 0;
					border-right-color: var(--kt-ribbon-clip-bg) !important;
					left: 0;
				}
			}
		}
	}

	&.ribbon-end {
		.ribbon-label {
			right: -5px;

			.ribbon-inner {
				border-top-left-radius: $border-radius;
				border-bottom-left-radius: $border-radius;

				&:before,
				&:after {
					content: '';
					position: absolute;
					border-style: solid;
					border-color: transparent !important;
					bottom: -10px;
				}

				&:before {
					border-width: 0 0 10px 10px;
					border-left-color: var(--kt-ribbon-clip-bg) !important;
					right: 0;
				}
			}
		}
	}
}

// Triangle style
.ribbon.ribbon-triangle {
	position: absolute;
	z-index: 1;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;

	// Top start position
	&.ribbon-top-start {
		top: 0;
		left: 0;
		width: 4rem;
		height: 4rem;
		border-bottom: solid 2rem transparent !important;
		border-left: solid 2rem transparent;
		border-right: solid 2rem transparent !important;
		border-top: solid 2rem transparent;
	}

	// Top end position
	&.ribbon-top-end {
		top: 0;
		right: 0;
		width: 4rem;
		height: 4rem;
		border-bottom: solid 2rem transparent !important;
		border-left: solid 2rem transparent !important;
		border-right: solid 2rem transparent;
		border-top: solid 2rem transparent;
	}

	// Botton start position
	&.ribbon-bottom-start {
		bottom: 0;
		left: 0;
		width: 4rem;
		height: 4rem;
		border-bottom: solid 2rem transparent;
		border-left: solid 2rem transparent;
		border-right: solid 2rem transparent !important;
		border-top: solid 2rem transparent !important;
	}

	// Botton end position
	&.ribbon-bottom-end {
		bottom: 0;
		right: 0;
		width: 4rem;
		height: 4rem;
		border-bottom: solid 2rem transparent;
		border-right: solid 2rem transparent;
		border-left: solid 2rem transparent !important;
		border-top: solid 2rem transparent !important;
	}
}
