//
// NoUiSlider
//

// Base
.noUi-target {
	border: 0;
	background: var(--kt-gray-100);
	box-shadow: none;

	&.noUi-horizontal {
		height: 15px;

		.noUi-handle {
			width: 24px;
			height: 24px;
			top: -4.5px;
			border-radius: 50%;
			outline: none;

			&::before {
				display: none;
			}

			&::after {
				display: none;
			}
		}
	}

	&.noUi-vertical {
		height: 150px;
		width: 15px;

		.noUi-handle {
			width: 24px;
			height: 24px;
			right: -4.5px;
			border-radius: 50%;
			outline: none;

			&::before {
				display: none;
			}

			&::after {
				display: none;
			}
		}
	}

	.noUi-connect {
		background: var(--kt-component-active-bg);
	}

	.noUi-handle {
		background-color: $white; //var(--kt-body-bg);
		border: 1px solid var(--kt-gray-200);
		box-shadow: var(--kt-box-shadow-sm);
	}

	// Small size
	&.noUi-sm {
		@include nouislider-size(6px, 20px, 20px, 7px);
	}

	// Large size
	&.noUi-lg {
		@include nouislider-size(18px, 30px, 30px, 6px);
	}
}

// Theme colors
@each $name, $value in $theme-colors {
	// NoUiSlider success color option
	.noUi-target.noUi-target-#{$name} {
		.noUi-connects {
			background-color: var(--kt-#{$name}-light);

			.noUi-connect {
				background-color: var(--kt-#{$name});
			}
		}

		.noUi-handle {
			border: 1px solid var(--kt-#{$name});
			box-shadow: 0 3px 6px -3px rgba(var(--kt-#{$name}), 0.7);
			background-color: var(--kt-#{$name});
		}
	}
}

// Tooltip
.noUi-tooltip {
	box-shadow: var(--kt-tooltip-box-shadow);
	background: var(--kt-tooltip-bg);
	color: var(--kt-tooltip-color);
	font-size: $tooltip-font-size;
	border: 0;
	padding: 0.5rem 0.75rem;
	@include border-radius($tooltip-border-radius);
}
