.createRole {
	input[type="checkbox"] {
		background-color: #F3F6F9;
		border: 0;
	}

	& input[type="checkbox"]:checked {
		background-color: #009ef7;
		background-size: 14px;
	}
}

