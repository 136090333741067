//
// Input placeholder color
//

@mixin placeholder($color) {
	// Chrome, Firefox, Opera, Safari 10.1+
	&::placeholder {
		color: $color;
	}

	// Firefox
	&::-moz-placeholder {
		color: $color;
		opacity: 1;
	}
}
