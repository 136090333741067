//
// Custom Header(used by Light Sidebar layout only)
//

[data-kt-app-layout='light-sidebar'] {
	// Desktop mode
	@include media-breakpoint-up(lg) {
		.app-header-menu {
			.menu {
				// Menu root item
				> .menu-item {
					@include menu-link-here-state(
						$title-color: var(--kt-primary),
						$icon-color: var(--kt-primary),
						$bullet-color: var(--kt-primary),
						$arrow-color: var(--kt-primary),
						$bg-color: var(--kt-app-sidebar-light-header-menu-link-bg-color-active),
						$all-links: false
					);

					@include menu-link-active-state(
						$title-color: var(--kt-primary),
						$icon-color: var(--kt-primary),
						$bullet-color: var(--kt-primary),
						$arrow-color: var(--kt-primary),
						$bg-color: var(--kt-app-sidebar-light-header-menu-link-bg-color-active),
						$all-links: false
					);
				}
			}
		}
	}
}

// Desktop mode
@include media-breakpoint-up(lg) {
	[data-kt-app-layout='light-sidebar']:not([data-kt-app-header-fixed='true']) {
		.app-header {
			background-color: transparent;
			box-shadow: none;
			border-bottom: 1px solid var(--kt-app-sidebar-light-separator-color);
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	[data-kt-app-layout='light-sidebar']:not([data-kt-app-header-fixed-mobile='true']) {
		.app-header {
			background-color: transparent;
			box-shadow: none;
			border-bottom: 1px solid var(--kt-app-sidebar-light-separator-color);
		}
	}
}
