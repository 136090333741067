.firstcontainer {
	display: flex;
	justify-content: space-between;
	padding-bottom: 20px;
	width: 1510px;
}
.firstcontainer .searchwrapper {
	position: relative;
}
.firstcontainer .searchwrapper img {
	position: relative;
	position: absolute;
	top: 13px;
	right: 230px;
}
.filtersection {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 14px;
	gap: 16px;
	width: 80px;
	height: 38px;
	background: #ffffff;
	border-radius: 6px;
	text-align: center;
}
.filter {
	width: 29px;
	height: 18px;

	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: #a1a5b7;
}
.tablename {
	display: flex;
	flex-wrap: wrap;
	gap: 10px 20px; /* row-gap [column-gap]*/
}
.tablename .app {
	width: 93px;
	height: 20px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 20px;
	color: #a1a5b7;
}
.tablename .date {
	padding-left: 20px;
	width: 32px;
	height: 20px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 20px;
	/* identical to box height */

	/* black/black 5 */

	color: #a1a5b7;
}
.tablename .user {
	padding-left: 130px;
	width: 30px;
	height: 20px;

	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 20px;
	/* identical to box height */

	/* black/black 5 */

	color: #a1a5b7;
}
.tablename .active {
	padding-left: 50px;
	width: 51px;
	height: 20px;

	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 20px;
	/* identical to box height */

	/* black/black 5 */

	color: #a1a5b7;
}
/* .tableName {
	display: flex;
	gap: 70px;
	row-gap: 80px;
	column-gap: 50px;
}
.tableNameList {
	display: flex;
}
.table th {
	padding: 0 12px;
	padding-bottom: 20px;
} */

/* .date {
	width: 32px;
	height: 20px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 20px;
	color: #a1a5b7;
} */
/* .search {
	width: 204px;
	height: 18px;

	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: #a1a5b7;
} */
.searchSection {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 10px 14px;
	gap: 7px;
	float: right;
	width: 249.67px;
	height: 38px;
	background: #ffffff;
	border-radius: 6px;
}
::placeholder {
	width: 204px;
	height: 18px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	color: #a1a5b7;
}
.secondcontainer {
	display: flex;
	flex-direction: column;
	padding: 30px;
	gap: 24px;

	width: 1510px;
	height: 820px;

	background: #ffffff;
	border-radius: 12px;
}
.latestactivities {
	width: 1296px;
	height: 21px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
	color: #212121;
}
.tablelist {
	display: flex;
	border-bottom-style: dotted;
	border-color: #e2e4eb;
	padding: 11px;
}

.s-show {
	padding-top: 20px;
}

.lastone .pagination {
	padding-top: 15px;
	padding-left: 1030px;
}
.lastone .show {
	/* padding: 30px; */
	border-color: #0424b1;
}
.pagination a {
	color: black;
	float: left;
	width: 1px;
	padding: 8px 13px;
}

.lastone .pagination a.active {
	width: 29px;
	height: 30px;
	/* left: 40px; */
	top: 0px;

	background: #00a3ff;
	border-radius: 6px;
	color: #ffffff;
}
/* .lastone .pagination .laquo {
	padding-left: 2px;
} */

.lastone {
	display: flex;
	padding-top: 5px;
}
