//
// Main
//

// General mode
.app-main {
	display: flex;
}

// Desktop mode
@include media-breakpoint-up(lg) {
	// Base
	.app-main {
		transition: $app-main-transition;

		@include property(padding-left, $app-main-padding-x);
		@include property(padding-right, $app-main-padding-x);
		@include property(padding-top, $app-main-padding-y);
		@include property(padding-bottom, $app-main-padding-y);

		@include property(margin-left, $app-main-margin-x);
		@include property(margin-right, $app-main-margin-x);
		@include property(margin-top, $app-main-margin-y);
		@include property(margin-bottom, $app-main-margin-y);
	}

	// Integration
	.app-main {
		// Sidebar
		[data-kt-app-sidebar-sticky='true'] & {
			margin-left: calc(
				var(--kt-app-sidebar-width) + var(--kt-app-sidebar-gap-start, 0px) + var(--kt-app-sidebar-gap-end, 0px)
			);
		}

		// Sidebar Panel
		[data-kt-app-sidebar-panel-sticky='true'] & {
			margin-left: calc(
				var(--kt-app-sidebar-width) + var(--kt-app-sidebar-gap-start, 0px) + var(--kt-app-sidebar-gap-end, 0px) +
					var(--kt-app-sidebar-panel-width) + var(--kt-app-sidebar-panel-gap-start, 0px) +
					var(--kt-app-sidebar-panel-gap-end, 0px)
			);
		}

		// Aside
		[data-kt-app-aside-sticky='true'] & {
			margin-right: calc(
				var(--kt-app-aside-width) + var(--kt-app-aside-gap-start, 0px) + var(--kt-app-aside-gap-end, 0px)
			);
		}
	}
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
	// Base
	.app-main {
		@include property(padding-left, $app-main-padding-x-mobile);
		@include property(padding-right, $app-main-padding-x-mobile);
		@include property(padding-top, $app-main-padding-y-mobile);
		@include property(padding-bottom, $app-main-padding-y-mobile);

		@include property(margin-left, $app-main-margin-x-mobile);
		@include property(margin-right, $app-main-margin-x-mobile);
		@include property(margin-top, $app-main-margin-y-mobile);
		@include property(margin-bottom, $app-main-margin-y-mobile);
	}
}
