.role1Tag {
	background: #dff1eb;
	color: #2ba579;
}

.role2Tag {
	background: #dcfdfd;
	color: #00afaf;
}

.role3Tag {
	background: #ecf8ff;
	color: #0095e8;
}

.role4Tag {
	background: #f0efff;
	color: #5f5cf1;
}

.role5Tag {
	background: #fff5f8;
	color: #d9214e;
}

.role6Tag {
	background: #fff5f8;
	color: #d9214e;
}

.role7Tag {
	background: #fff5f8;
	color: #d9214e;
}

.role8Tag {
	background: #fff5f8;
	color: #d9214e;
}

.role9Tag {
	background: #fff5f8;
	color: #d9214e;
}

.role10Tag {
	background: #fff5f8;
	color: #d9214e;
}

.unknownTag {
	background: #eff2f5;
	color: #a1a5b7;
}
