//
// Form Check
//

// Check input
.form-check:not(.form-switch) {
	.form-check-input {
		&[type='checkbox'] {
			background-size: $form-check-input-bg-size;
		}
	}
}

// Custom checkbox and radio
.form-check-custom {
	display: flex;
	align-items: center;
	padding-left: 0;
	margin: 0;

	// Input
	.form-check-input {
		margin: 0;
		float: none;
		flex-shrink: 0;
	}

	// Label
	.form-check-label {
		margin-left: 0.55rem;
	}

	// Sizing
	&.form-check-sm {
		.form-check-input {
			height: $form-check-input-width-sm;
			width: $form-check-input-width-sm;
		}
	}

	&.form-check-lg {
		.form-check-input {
			height: $form-check-input-width-lg;
			width: $form-check-input-width-lg;
		}
	}

	// Solid
	&.form-check-solid {
		.form-check-input {
			border: 0;
			background-color: var(--kt-form-check-input-bg-solid);

			&:active,
			&:focus {
				filter: none;
				background-color: var(--kt-form-check-input-bg-solid);
			}

			&:checked {
				background-color: var(--kt-form-check-input-checked-bg-color-solid);
			}
		}
	}

	// States
	// Success state
	&.form-check-success {
		.form-check-input {
			&:checked {
				background-color: var(--kt-success);
			}
		}
	}

	// Danger state
	&.form-check-danger {
		.form-check-input {
			&:checked {
				background-color: var(--kt-danger);
			}
		}
	}

	// Warning state
	&.form-check-warning {
		.form-check-input {
			&:checked {
				background-color: var(--kt-warning);
			}
		}
	}
}

// Custom switch
.form-switch.form-check-solid {
	.form-check-input {
		height: $form-switch-height;
		background-image: var(--kt-form-switch-bg-image-solid);
		border-radius: $form-switch-border-radius;

		&:checked {
			filter: none;
			background-image: var(--kt-form-switch-checked-bg-image);
		}
	}

	&.form-switch-sm {
		.form-check-input {
			height: $form-switch-height-sm;
			width: $form-switch-width-sm;
		}
	}

	&.form-switch-lg {
		.form-check-input {
			height: $form-switch-height-lg;
			width: $form-switch-width-lg;
		}
	}
}

// Clip
.form-check-clip {
	position: relative;
	overflow: hidden;

	.form-check-label {
		padding-top: 0.5rem;
		font-size: $form-label-font-size;
		color: var(--kt-form-label-color);
		font-weight: $form-label-font-weight;
	}

	.form-check-wrapper {
		@include border-radius($border-radius-lg);
		border: 2px solid transparent;
		transition: $transition-base;
		cursor: pointer;
		overflow: hidden;
	}

	.form-check-indicator {
		transition: $transition-base;
		position: absolute;
		top: 0;
		right: 0;
		opacity: 0;
		width: $form-check-input-width;
		height: $form-check-input-width;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		background-size: 50%;
		background-image: var(--kt-form-check-input-checked-bg-image);
		background-color: var(--kt-component-checked-bg);
		@include border-bottom-start-radius($border-radius-lg);
		@include border-top-end-radius($border-radius-lg);

		&.form-check-indicator-sm {
			width: $form-check-input-width-sm;
			height: $form-check-input-width-sm;
		}

		&.form-check-indicator-lg {
			width: $form-check-input-width-lg;
			height: $form-check-input-width-lg;
		}
	}

	.form-check-content {
		width: 100%;
	}

	.btn-check:checked + div {
		border: 2px solid var(--kt-component-checked-bg);
		transition: $transition-base;

		.form-check-indicator {
			transition: $transition-base;
			opacity: 1;
		}
	}

	.btn-check:disabled + div {
		transition: $transition-base;
		opacity: var(--kt-form-check-btn-check-disabled-opacity);
	}
}

// Image
.form-check-image {
	position: relative;
	overflow: hidden;

	img {
		max-width: 100%;
	}

	.form-check-wrapper {
		@include border-radius($border-radius-lg);
		border: 2px solid transparent;
		transition: $transition-base;
		cursor: pointer;
		overflow: hidden;
		margin-bottom: 0.75rem;
	}

	.form-check-rounded {
		@include border-radius($border-radius-lg);
	}

	.form-check-label {
		font-weight: $font-weight-bold;
		margin-left: 0.5rem;
	}

	&.active {
		.form-check-wrapper {
			border-color: var(--kt-primary);
		}
	}

	&.form-check-success.active {
		.form-check-wrapper {
			border-color: var(--kt-success);
		}
	}

	&.form-check-danger.active {
		.form-check-wrapper {
			border-color: var(--kt-danger);
		}
	}

	&.disabled {
		opacity: var(--kt-form-check-btn-check-disabled-opacity);
	}
}
