.report-blocks {
	margin-bottom: 25px;
}

.report-blocks p {
	color: #000;
}
.report-block {
	padding: 25px 27px;
}
