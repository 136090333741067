.menu-link-delete {
	&:hover {
		background-color: #fff5f8 !important;
		color: #d9214e !important;
	}
}

.table-action-btn {
	color: #4a7dff;
	background: #f1f5ff;
	border: 1px solid #4a7dff;
}

.table-title {
	color: #212121;
}

.bkash-data-table {
	thead {
		color: #a1a5b7;
	}
	tbody {
		color: #464e5f;

		tr {
			border-bottom: 1px dashed #e4e6ef !important;

			&:last-child {
				border-bottom: 1px dashed #e4e6ef !important;
			}
		}
		td {
			padding-top: 19px;
			padding-bottom: 21px;
		}
	}
}

.fixed-header-under-navbar {
	margin-top: var(--kt-app-header-height);
	left: calc(var(--kt-app-sidebar-width) + var(--kt-app-sidebar-gap-start, 0px) + var(--kt-app-sidebar-gap-end, 0px));
	right: 0;
	position: fixed;
	z-index: 50;
	box-shadow: -7px 2px #f7f2f2;
	margin-bottom: 10px;
	// margin-left: 10px;
}
